import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4a231f80"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "screen-player-item"
};
var _hoisted_2 = {
  "class": "header"
};
var _hoisted_3 = {
  "class": "title"
};
var _hoisted_4 = {
  style: {
    "display": "inline-flex",
    "align-items": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.currentDrone ? (_openBlock(), _createBlock($setup["AircraftPlayer"], {
    key: 0,
    drone: $setup.currentDrone,
    style: {
      "height": "100%"
    },
    showBottom: $setup.isDockDevice
  }, _createSlots({
    header: _withCtx(function () {
      var _a;
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        "class": _normalizeClass(["pointer", $setup.aircraftPlaying ? 'pointer-online' : ''])
      }, null, 2 /* CLASS */), _createElementVNode("div", _hoisted_3, _toDisplayString((_a = $setup.currentDrone) === null || _a === void 0 ? void 0 : _a.droneName), 1 /* TEXT */), _createVNode(_component_el_dropdown, {
        style: {
          "z-index": "1000"
        },
        onCommand: $setup.handleClick
      }, {
        dropdown: _withCtx(function () {
          return [_createVNode(_component_el_dropdown_menu, null, {
            "default": _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.droneList, function (item) {
                return _openBlock(), _createBlock(_component_el_dropdown_item, {
                  key: item.droneId,
                  command: item.droneId
                }, {
                  "default": _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
                      "class": _normalizeClass(["pointer", $setup.JlinkMqtt2.getReactiveDevice(item).getAircraftLiveStream().isPlaying() ? 'pointer-online' : ''])
                    }, null, 2 /* CLASS */), _createTextVNode(" " + _toDisplayString(item.droneName), 1 /* TEXT */)])];
                  }),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["command"]);
              }), 128 /* KEYED_FRAGMENT */))];
            }),
            _: 1 /* STABLE */
          })];
        }),
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepSvgIcon"], {
            name: "switch",
            "class": "icon"
          })];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    "bottom-left": _withCtx(function () {
      return [_ctx.$props.control && $setup.isDockDevice && $setup.aircraftPlaying && $setup.canControl ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
        key: 0,
        name: $setup.controllerAble ? 'pan-tilt-controls' : 'pan-tilt-control',
        "class": "controls-icon",
        onClick: $setup.handleController
      }, null, 8 /* PROPS */, ["name"])) : _createCommentVNode("v-if", true)];
    }),
    _: 2 /* DYNAMIC */
  }, [$setup.isDockDevice ? {
    name: "scrim",
    fn: _withCtx(function () {
      return [_createVNode($setup["AircraftCameraControlScrim"], {
        show: $setup.controllerAble,
        drone: $setup.currentDrone
      }, null, 8 /* PROPS */, ["show", "drone"])];
    }),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["drone", "showBottom"])) : _createCommentVNode("v-if", true)]);
}