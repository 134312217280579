import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0b4f0c3c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "screen-player-item2"
};
var _hoisted_2 = ["data-name"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepAspectView"], {
    aspect: "16: 9"
  }, {
    "default": _withCtx(function () {
      return [$setup.currentDroneValue ? (_openBlock(), _createBlock($setup["DeepRtcPlayer"], {
        key: 0,
        drone: $setup.currentDroneValue
      }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", {
    "class": "mask",
    onClick: $setup.handleSwitch,
    "data-name": (_a = $setup.currentDroneValue) === null || _a === void 0 ? void 0 : _a.droneId
  }, _toDisplayString((_b = $setup.currentDroneValue) === null || _b === void 0 ? void 0 : _b.droneName), 9 /* TEXT, PROPS */, _hoisted_2)]);
}