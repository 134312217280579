import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition, resolveComponent as _resolveComponent, withKeys as _withKeys, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  "class": "assignments-message"
};
var _hoisted_2 = {
  key: 0,
  "class": "message-record"
};
var _hoisted_3 = {
  "class": "info"
};
var _hoisted_4 = {
  "class": "name"
};
var _hoisted_5 = {
  "class": "content"
};
var _hoisted_6 = {
  key: 0,
  "class": "record-panel"
};
var _hoisted_7 = {
  "class": "header"
};
var _hoisted_8 = {
  "class": "message-container"
};
var _hoisted_9 = {
  "class": "message-panel-record"
};
var _hoisted_10 = {
  "class": "date"
};
var _hoisted_11 = {
  "class": "info"
};
var _hoisted_12 = {
  "class": "name"
};
var _hoisted_13 = {
  "class": "content"
};
var _hoisted_14 = {
  "class": "send-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.receiveMessageList, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [item.content ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode($setup["DeepUserIcon"], {
      "class": "user",
      src: item.sender
    }, null, 8 /* PROPS */, ["src"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.sender.userName) + ":", 1 /* TEXT */), _createElementVNode("div", _hoisted_5, _toDisplayString(item.content), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), _createVNode(_Transition, {
    name: "opacity-fade-transform",
    mode: "out-in"
  }, {
    "default": _withCtx(function () {
      return [$setup.recordPanelOpen ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, " 消息(" + _toDisplayString($setup.total) + ") ", 1 /* TEXT */), _createElementVNode("div", _hoisted_8, [_createVNode($setup["DeepRefreshView"], {
        ref: "refreshViewRef"
      }, {
        "default": _withCtx(function (_a) {
          var _b;
          var data = _a.data;
          return [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString($setup.JlinkUtils.date.parseTime(data.createTime, '{y}-{m}-{d} {h}:{i}:{s}')), 1 /* TEXT */), _createElementVNode("div", {
            "class": _normalizeClass(["message-item", data.own ? 'message-item-reverse' : ''])
          }, [_createVNode($setup["DeepUserIcon"], {
            "class": "user",
            lazy: "",
            src: data.sender
          }, null, 8 /* PROPS */, ["src"]), _createElementVNode("div", _hoisted_11, [_createElementVNode("text", _hoisted_12, _toDisplayString((_b = data.sender) === null || _b === void 0 ? void 0 : _b.userName), 1 /* TEXT */), _createElementVNode("text", _hoisted_13, _toDisplayString(data.content), 1 /* TEXT */)])], 2 /* CLASS */)])];
        }),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_14, [_createVNode(_component_el_input, {
    modelValue: $setup.messageValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.messageValue = $event;
    }),
    placeholder: "请输入消息内容",
    onKeyup: _withKeys($setup.sendMqttMessage, ["enter"])
  }, null, 8 /* PROPS */, ["modelValue"]), _createVNode($setup["DeepButton"], {
    style: {
      "background": "#323eaf",
      "border-color": "#323eaf"
    },
    type: "primary",
    size: "small",
    onClick: $setup.sendMqttMessage,
    disabled: !$setup.messageValue
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("发送")];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled"]), _createVNode($setup["DeepButton"], {
    style: {
      "background": "#4fb41d"
    },
    type: "success",
    size: "small",
    onClick: $setup.toggleRecordPanel
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.recordPanelOpen ? "关闭记录" : "打开记录"), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })])]);
}