import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6bb3a9be"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "left-overlay"
};
var _hoisted_2 = {
  "class": "share-live-button"
};
var _hoisted_3 = {
  "class": "player-group"
};
var _hoisted_4 = {
  "class": "header"
};
var _hoisted_5 = {
  "class": "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  var _component_DeepQR = _resolveComponent("DeepQR");
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["assignments-play-list", $setup.show ? '' : 'assignments-play-list-hide']),
    style: _normalizeStyle({
      width: $setup.currentWidth + 'px'
    })
  }, [_createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepHammerView"], {
    onTap: $setup.clickDrag,
    type: ['tap', 'pin'],
    onPan: $setup.handleDrag,
    "class": _normalizeClass(["drag", [$setup.show ? '' : 'drag-close', $setup.dragStart ? 'dragging' : '']]),
    style: _normalizeStyle(_ctx.$props.assignmentsOpen ? '' : 'background:transparent')
  }, {
    "default": _withCtx(function () {
      return [_ctx.$props.assignmentsOpen ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
        key: 0,
        name: "svg-arrow-left",
        style: {
          "color": "white"
        }
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "style"]), $setup.droneIds && $setup.show && $setup.showAble ? (_openBlock(), _createBlock(_component_DeepQR, {
    key: 0,
    "class": "share",
    remote: $setup.livestreamPath
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_tooltip, {
        placement: "left",
        content: "分享"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_createVNode($setup["DeepSvgIcon"], {
            name: "svg-share"
          })])];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.drones, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: item.droneId,
      "class": "player-panel"
    }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
      "class": _normalizeClass(["pointer", $setup.JlinkMqtt2.getReactiveDevice(item).getAircraftLiveStream().isPlaying() ? 'pointer-online' : ''])
    }, null, 2 /* CLASS */), _createElementVNode("div", _hoisted_5, _toDisplayString(item.droneName), 1 /* TEXT */)]), item.temp ? (_openBlock(), _createBlock($setup["GatewayPlayer"], {
      key: 0,
      "class": "live-player",
      drone: item,
      index: index
    }, null, 8 /* PROPS */, ["drone", "index"])) : $setup.JlinkType.drone.isDock(item) ? (_openBlock(), _createBlock($setup["PlayerDockItem"], {
      key: 1,
      "class": "live-player",
      drone: item,
      index: index
    }, null, 8 /* PROPS */, ["drone", "index"])) : (_openBlock(), _createBlock($setup["PlayerAircraftItem"], {
      key: 2,
      "class": "live-player",
      drone: item,
      index: index
    }, null, 8 /* PROPS */, ["drone", "index"]))]);
  }), 128 /* KEYED_FRAGMENT */))])], 6 /* CLASS, STYLE */);
}