import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3d0f0c2e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 1,
  "class": "hms"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b, _c;
  return _openBlock(), _createElementBlock("div", _mergeProps({
    "class": "drone-hms-item"
  }, _ctx.$attrs), [_createElementVNode("span", {
    "class": _normalizeClass(["status", $setup.modeDesc.level])
  }, _toDisplayString($setup.modeDesc.name), 3 /* TEXT, CLASS */), ((_a = $setup.warningInfo) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    "class": _normalizeClass(["hms", $setup.warningInfo[0].style]),
    onClick: $setup.handleWarning
  }, [_createElementVNode("i", null, _toDisplayString((_b = $setup.warningInfo) === null || _b === void 0 ? void 0 : _b.length), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString((_c = $setup.warningInfo[0]) === null || _c === void 0 ? void 0 : _c.text), 1 /* TEXT */)], 2 /* CLASS */)) : $setup.JlinkMqtt2.getGatewayOnline($setup.drone) ? (_openBlock(), _createElementBlock("span", _hoisted_1, "状态正常")) : _createCommentVNode("v-if", true)], 16 /* FULL_PROPS */);
}