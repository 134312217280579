import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8fe06120"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "drone-parts-control-item"
};
var _hoisted_2 = {
  "class": "title"
};
var _hoisted_3 = {
  "class": "span"
};
var _hoisted_4 = {
  style: {
    "flex": "1",
    "margin": "0 0.5rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($setup.item.dronePartsNickName), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepDronePartIcon"], {
    "class": "icon",
    src: $setup.item
  }, null, 8 /* PROPS */, ["src"]), _createElementVNode("div", _hoisted_4, _toDisplayString($setup.item.dronePartsName), 1 /* TEXT */)])]);
}