import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_switch = _resolveComponent("el-switch");
  return !$props.param.disabled ? (_openBlock(), _createBlock(_component_el_switch, {
    key: 0,
    size: $props.size,
    "model-value": $setup.value1,
    loading: $setup.loading1,
    onChange: $setup.onChange,
    "active-text": $props.param.activeDesc,
    "inactive-text": $props.param.unActiveDesc
  }, null, 8 /* PROPS */, ["size", "model-value", "loading", "active-text", "inactive-text"])) : _createCommentVNode("v-if", true);
}