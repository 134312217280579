import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4dbc841a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "mode"
};
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b, _c, _d;
  var _component_DeepSvgIcon = _resolveComponent("DeepSvgIcon");
  return _openBlock(), _createElementBlock("div", _mergeProps({
    "class": "drone-hms-item3"
  }, _ctx.$attrs), [_createVNode(_component_DeepSvgIcon, {
    name: $setup.icon
  }, null, 8 /* PROPS */, ["name"]), _createElementVNode("div", {
    "class": _normalizeClass(["mode-level", $setup.modeDesc.level])
  }, null, 2 /* CLASS */), _createElementVNode("div", _hoisted_1, _toDisplayString($setup.modeDesc.name), 1 /* TEXT */), _createElementVNode("div", {
    "class": _normalizeClass(["hms-level", (_a = $setup.warningInfo[0]) === null || _a === void 0 ? void 0 : _a.style])
  }, [((_b = $setup.warningInfo) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString((_c = $setup.warningInfo) === null || _c === void 0 ? void 0 : _c.length), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
    "class": "hms",
    onClick: $setup.handleWarning
  }, [_createElementVNode("span", null, _toDisplayString(((_d = $setup.warningInfo[0]) === null || _d === void 0 ? void 0 : _d.text) || '状态正常'), 1 /* TEXT */)])], 16 /* FULL_PROPS */);
}