import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-518919a6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  style: {
    "position": "absolute",
    "left": "0",
    "right": "0",
    "height": ".6rem"
  }
};
var _hoisted_2 = {
  "class": "top"
};
var _hoisted_3 = {
  "class": "right"
};
var _hoisted_4 = {
  "class": "name"
};
var _hoisted_5 = {
  "class": "center"
};
var _hoisted_6 = {
  key: 1,
  "class": "bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b;
  var _component_el_progress = _resolveComponent("el-progress");
  return _openBlock(), _createElementBlock("div", {
    "class": "dock-control-item",
    onClick: $setup.handleSelect
  }, [$setup.taskStart && !$setup.taskStart.end ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_progress, {
    "show-text": false,
    percentage: (_a = $setup.taskStart) === null || _a === void 0 ? void 0 : _a.percent,
    "stroke-width": 3,
    style: {
      "width": "100%"
    },
    "stroke-linecap": "square"
  }, null, 8 /* PROPS */, ["percentage"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode($setup["DeepDroneImage"], {
    drone: $setup.drone,
    gateway: ""
  }, null, 8 /* PROPS */, ["drone"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString((_b = $setup.drone) === null || _b === void 0 ? void 0 : _b.gatewayName), 1 /* TEXT */), _createVNode($setup["DroneHmsItem"], {
    type: "dock",
    drone: $setup.drone
  }, null, 8 /* PROPS */, ["drone"])])]), _createElementVNode("div", _hoisted_5, [$setup.JlinkMqtt2.getGatewayOnline($setup.drone) ? (_openBlock(), _createBlock($setup["DockOsdItem"], {
    key: 0,
    drone: $setup.drone
  }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true)]), $setup.JlinkType.user.isAdmin && $setup.JlinkMqtt2.getGatewayOnline($setup.drone) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createCommentVNode("      <DeepButton class=\"label\" icon=\"live-camera\" @click=\"handleLiveStreamOpen2\" type=\"primary\" size=\"small\">{{ liveStreamDesc }}</DeepButton>"), _createVNode($setup["DeepButton"], {
    "class": "label",
    icon: "live-camera",
    onClick: $setup.handleLiveStreamOpen,
    type: "primary",
    size: "small"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.liveStreamDesc), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepButton"], {
    "class": "label",
    icon: "infomation",
    onClick: $setup.handleDetail,
    type: "primary",
    size: "small"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("信息")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepButton"], {
    "class": "label",
    icon: "arrow-go-back",
    onClick: $setup.handleReturnHome,
    type: "danger",
    size: "small",
    disabled: !$setup.isAircraftReturnAble
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("返航")];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled"])])) : _createCommentVNode("v-if", true)]);
}