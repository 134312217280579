import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  "class": "map-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.iconList, function (item) {
    return _openBlock(), _createElementBlock("div", {
      "class": "icons",
      key: item.id
    }, [_createVNode(_component_el_tooltip, {
      placement: "left",
      content: item.name
    }, {
      "default": _withCtx(function () {
        return [_createVNode($setup["DeepSvgIcon"], {
          name: item.icon,
          "class": "icon",
          style: _normalizeStyle({
            color: item.flag ? null : '#323eaf'
          }),
          onClick: function onClick($event) {
            return $setup.handleIcon(item.id);
          }
        }, null, 8 /* PROPS */, ["name", "style", "onClick"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}