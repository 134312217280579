import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-46056672"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "docker-player"
};
var _hoisted_2 = {
  key: 1,
  style: {
    "height": ".2rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.dockOpen ? (_openBlock(), _createBlock($setup["GatewayPlayer"], {
    key: 0,
    drone: $setup.drone
  }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true), $setup.dockOpen && $setup.aircraftPlaying ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("v-if", true), $setup.JlinkMqtt2.getAircraftOnline($setup.drone) && $setup.aircraftPlaying ? (_openBlock(), _createBlock($setup["AircraftPlayer"], {
    key: 2,
    "class": "aircraft-player",
    drone: $setup.drone
  }, {
    "top-left": _withCtx(function () {
      return [$setup.canControl ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
        key: 0,
        name: $setup.controllerAble ? 'pan-tilt-controls' : 'pan-tilt-control',
        "class": "controls-icon",
        onClick: $setup.handleController
      }, null, 8 /* PROPS */, ["name"])) : _createCommentVNode("v-if", true)];
    }),
    scrim: _withCtx(function () {
      return [_createVNode($setup["AircraftCameraControlScrim"], {
        show: $setup.controllerAble,
        drone: $setup.drone
      }, null, 8 /* PROPS */, ["show", "drone"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true)]);
}