import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-29750ab1"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "remote-switch2"
};
var _hoisted_2 = {
  "class": "desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_switch = _resolveComponent("el-switch");
  return !$props.param.disabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_switch, {
    size: $props.size,
    modelValue: $setup.value1,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.value1 = $event;
    }),
    loading: $setup.loading1,
    onChange: $setup.onChange
  }, null, 8 /* PROPS */, ["size", "modelValue", "loading"]), _createElementVNode("div", _hoisted_2, _toDisplayString($setup.value1 ? $props.param.unActiveDesc : $props.param.activeDesc), 1 /* TEXT */)])) : _createCommentVNode("v-if", true);
}