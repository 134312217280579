import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5e244695"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "user-control-item"
};
var _hoisted_2 = {
  "class": "name"
};
var _hoisted_3 = {
  key: 0,
  "class": "login-count"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepUserIcon"], {
    "class": "icon",
    lazy: false,
    src: $props.item,
    offline: !$props.item.isEnter
  }, null, 8 /* PROPS */, ["src", "offline"]), _createElementVNode("div", _hoisted_2, _toDisplayString($props.item.userName), 1 /* TEXT */), _createVNode(_component_el_tooltip, {
    placement: "left",
    content: "多端登录"
  }, {
    "default": _withCtx(function () {
      return [$props.item.loginCount > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.item.loginCount), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}