import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0d701bac"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "aircraft-player"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $setup.aircraftPlaying ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["AircraftPlayer"], {
    drone: $setup.drone
  }, {
    "top-left": _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.title), 1 /* TEXT */)];
    }),
    "top-right": _withCtx(function () {
      return [_createVNode($setup["LabelBattery"], {
        drone: $setup.drone,
        "class": "battery"
      }, null, 8 /* PROPS */, ["drone"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["drone"])])) : _createCommentVNode("v-if", true);
}