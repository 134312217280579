import { createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0a7b7450"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "label-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _mergeProps({
    "class": "dock-osd-item"
  }, _ctx.$attrs), [_createElementVNode("div", _hoisted_1, [_createVNode($setup["OsdLabel"], {
    "class": "label",
    desc: "运行时长",
    icon: "timer",
    value: $setup.accTime
  }, null, 8 /* PROPS */, ["value"]), _createVNode($setup["OsdLabel"], {
    "class": "label",
    desc: "风速",
    icon: "windy",
    value: $setup.windSpeedDesc
  }, null, 8 /* PROPS */, ["value"]), _createVNode($setup["OsdLabel"], {
    "class": "label",
    desc: "环境温度",
    icon: "temperature",
    value: $setup.environmentTemperatureDesc
  }, null, 8 /* PROPS */, ["value"]), _createVNode($setup["OsdLabel"], {
    "class": "label",
    desc: "雨量",
    icon: "rainy",
    value: $setup.rainfallDesc
  }, null, 8 /* PROPS */, ["value"]), _createVNode($setup["OsdLabelWireless"], {
    "class": "label",
    drone: $props.drone
  }, null, 8 /* PROPS */, ["drone"]), _createVNode($setup["OsdLabelNet"], {
    "class": "label",
    drone: $props.drone
  }, null, 8 /* PROPS */, ["drone"])])], 16 /* FULL_PROPS */);
}